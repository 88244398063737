/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


.container-x-padding:not(.container-x-padding .container-x-padding) {
  @apply px-4 sm:px-6 lg:px-8;
}

.caption {
  @apply mt-2 text-base text-gray-700;
}


.text-link {
  @apply text-blue-700 hover:underline hover:text-blue-800 decoration-1 underline-offset-2 decoration-blue-500 hover:decoration-blue-800;
  @apply group-hover:underline group-hover:text-blue-800 group-hover:decoration-blue-800;
}

.prose {
  @apply prose-a:font-normal prose-a:text-blue-700 prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-500 ;
  @apply hover:prose-a:text-blue-800 hover:prose-a:decoration-blue-800;
  @apply prose-ul:pl-6;
  @apply prose-headings:font-normal prose-headings:mb-0;
  @apply prose-h2:text-3xl prose-h2:font-serif prose-h2:text-burgundy-800 prose-h2:pb-4 prose-h2:leading-normal;
  @apply prose-blockquote:font-normal prose-blockquote:not-italic prose-blockquote:text-current prose-blockquote:border-none prose-blockquote:pl-8;
}

.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.prose blockquote footer {
  @apply text-right;
}



.prose a[href*="//"]:not([href*="https://www.bahai.ca/"]):not([href*="https://www.datocms-assets.com/"]) {
  content:"";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='black' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* https://www.svgbackgrounds.com/tools/svg-to-css/ */
  @apply pr-[18px] bg-right bg-no-repeat bg-opacity-90 ;
  background-size: 1rem;
}

.prose.prose-white a[href*="//"]:not([href*="https://www.bahai.ca/"]):not([href*="https://www.datocms-assets.com/"]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='white' aria-hidden='true'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'/%3E%3C/svg%3E");
  /* https://www.svgbackgrounds.com/tools/svg-to-css/ */
}



.site-footer-logo {
  width: 140px;
  margin-left: -70px;
  position: absolute;
  top: -58px;
  left: 50%;
}
